.container {
  padding: 20px;
}

.btn-container {
  text-align: center;
  margin-bottom: 10px;
}

.button {
  background-color: #ff4757;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
}

.table-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.contact-card {
  background-color: #f1f2f6;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  width: calc(50% - 20px);
  max-width: 400px;
}

.contact-card div {
  margin-bottom: 10px;
}

.btn-group {
  display: flex;
  gap: 10px;
}

.btn {
  border: none;
  border-radius: 5px;
  color: white;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
}

.btn-edit {
  background-color: #008cba;
}

.btn-delete {
  background-color: #f44336;
}

.btn-view {
  background-color: #e7e7e7;
  color: black;
}

@media screen and (max-width: 768px) {
  .contact-card {
    width: calc(100% - 20px);
  }
}
